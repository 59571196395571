import ApiService from "../api";

export default class CommentService extends ApiService {

    constructor() {
        super('/api/comments')
    }

    del(commentId, userEmail) {
        return this.delete(`/${commentId}/user/${userEmail}`);
    }


}