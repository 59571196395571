import 'bootswatch/dist/litera/bootstrap.css'
import 'primereact/resources/primereact.css'
import 'primeicons/primeicons.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import 'primereact/resources/themes/md-light-indigo/theme.css'

import './App.css';
import Router from './routes/router';
import { AuthProvider } from './context/authContext'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {

  return (
    <AuthProvider value={{ user: {}, authenticated: Boolean }}>
      <ToastContainer theme='colored' />
      <Router />
    </AuthProvider>
  );
}

export default App;
