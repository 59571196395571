import React from 'react';
import './styles.css';

function Footer() {

    const downloadApk = () => {
        window.location.href = "https://vps54476.publiccloud.com.br/images/icons/Suporte Distribuidora York Setup 1.0.0.exe";
    }

    return (
        <footer className='fixed-bottom text-secondary py-1'>
            {window.innerWidth > 600 &&
                <div className='d-flex justify-content-between px-3'>
                    <label className="footer-link" onClick={downloadApk}><i className='pi pi-download' /> Baixar instalador Windows</label>
                    <label>Copyright © 2024 - Suporte T.I</label>
                </div>
            }
        </footer>
    )
}

export default Footer;

