import { useEffect } from 'react'; // Ajuste o caminho conforme necessário
import { useAuth } from '../context/authContext';
import LOGO from '../assets/profile.jpg'
import { useWebSocket } from '../services/useWebSocket';

const useCommentWebSocket = () => {
    const { user, authenticated } = useAuth();
    const { connectWebSocket, isConnect, stompClient } = useWebSocket();

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (stompClient && !isConnect) {
                stompClient.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (authenticated) {
            if (isConnect && stompClient) {
                const subscription = stompClient.subscribe(`/comment/user/${user.sub}`, (message) => {
                    const newComment = JSON.parse(message.body);

                    // Verifica se a notificação já foi exibida com base no ID do comentário
                    const lastCommentId = localStorage.getItem("lastCommentId");

                    // Se o comentário recebido for diferente do último notificado
                    if (!lastCommentId || lastCommentId.toString() !== newComment.id.toString()) {
                        localStorage.setItem("lastCommentId", newComment.id);

                        // Verifica se o comentário não é do próprio usuário
                        if (newComment.userEmail !== user.sub) {

                            // Permissão para exibir notificações
                            if (Notification.permission === "granted") {
                                const notification = new Notification(
                                    `${newComment.userName} enviou uma mensagem para você:`,
                                    {
                                        body: newComment.comment,
                                        icon: LOGO
                                    }
                                );

                                notification.onclick = function () {
                                    window.focus();
                                    window.location.href = "/suporte/minhas-solicitacoes/#/" + newComment.calledTicket;
                                };
                            }
                        }
                    }
                });

                // Limpeza ao desmontar o componente
                return () => {
                    subscription.unsubscribe();
                };
            }
        }
    }, [stompClient, isConnect]);

};

export default useCommentWebSocket;
