import React, { useState, useEffect, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Button } from 'primereact/button';
import { Image } from 'primereact/image';
import { Dialog } from "primereact/dialog";
import { OverlayPanel } from 'primereact/overlaypanel';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import { Card } from 'primereact/card';
import { Carousel } from 'primereact/carousel';
import { FileUpload } from 'primereact/fileupload';
import { Tag } from 'primereact/tag';
import { useAuth } from '../../context/authContext';

import CalledService from '../../services/resource/calledService';
import moment from "moment-timezone";
import LocalStorageService from '../../services/resource/localstorageService';

import { useWebSocket } from '../../services/useWebSocket';
import { useParams } from 'react-router-dom';
import { VisualizarChamadoSkeleton } from '../../components/skeleton/visualizar-chamado-skeleton';
import useCommentWebSocket from '../../utils/useCommentWebSocket ';
import CommentService from '../../services/resource/commentService';

const initalValues = {
    assigneeEmail: "",
    assigneeName: "",
    attachments: [],
    attributes: {},
    branchCode: "",
    branchName: "",
    comments: [],
    creationDate: "",
    description: "",
    id: 0,
    ticket: "",
    priority: "",
    requesterEmail: "",
    requesterName: "",
    serviceType: {
        attributes: {
            var: ''
        }
    },
    serviceTypeDescription: "",
    solutionApplied: "",
    status: "RESOLVIDO",
    title: "",
    updateDate: "",
    userEmail: "",
    userName: "",
}
const initialComment = {
    comment: '',
    date: '',
    userEmail: null,
    readSender: false,
    readReceiver: true,
    file: false,
}

const isImage = (image) => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'svg', 'ico', 'raw', 'bmp', 'heic'];
    const extension = image.split('.').pop();
    return imageExtensions.includes(extension.toLowerCase());
};

export const VisualizarChamado = ({ ticket }) => {
    const calledService = new CalledService();
    const commentService = new CommentService();
    const chatRef = useRef(null);
    const canvasRef = useRef(null);
    const op = useRef(null);
    const opx = useRef(null);
    const buttonRef = useRef(null);
    const { user } = useAuth();
    const { calledTicket } = useParams();
    const { connectWebSocket, isConnect, stompClient } = useWebSocket();
    const FileDownload = require('js-file-download');

    const [comment, setComment] = useState(initialComment);
    const [selectedComment, setSelectedComment] = useState(initialComment);
    const [currentTicket, setCurrentTicket] = useState(ticket);
    const [called, setCalled] = useState(initalValues);
    const [showNewAttachmentDialog, setShowNewAttachmentDialog] = useState(false);
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingAttchment, setLoadingAttchment] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const [comments, setComments] = useState([]);
    const [showDetails, setShowDetails] = useState(localStorage.getItem('showDetails') !== 'false');
    const [showRequester, setShowRequester] = useState(true);
    const [showResponsibility, setShowResponsibility] = useState(localStorage.getItem('showResponsibility') !== 'false');
    const [showAttachment, setShowAttachment] = useState(true);
    const [showDates, setShowDates] = useState(localStorage.getItem('showDates') !== 'false');
    const fileUploadRef = useRef(null);

    useEffect(() => {
        localStorage.setItem('showDetails', showDetails);
        localStorage.setItem('showResponsibility', showResponsibility);
        localStorage.setItem('showDates', showDates);
    }, [showDetails, showResponsibility, showDates]);

    useEffect(() => {
        const fetchTicketData = () => {
            if (calledTicket) {
                setCurrentTicket(calledTicket);
                listCalledByTicket(calledTicket);
            } else {
                listCalledByTicket(currentTicket);
            }
        };

        fetchTicketData();
    }, []);

    useEffect(() => {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
    }, [comments, loading]);

    useEffect(() => {
        const fetchImages = async () => {
            const imageUrls = [];
            const filesUrls = [];
            for (const obj of called.attachments) {
                if (isImage(obj)) {
                    try {
                        const response = await calledService.getAttachments(obj);
                        const blob = response.data;
                        const url = window.URL.createObjectURL(blob);
                        imageUrls.push(url);
                    } catch (error) {
                        toast.error(error.message);
                    }
                } else {
                    filesUrls.push(obj);
                }
            }
            setImages(imageUrls);
            setFiles(filesUrls);
        };


        fetchImages();
    }, [called.attachments]);

    useEffect(() => {
        connectWebSocket();

        return () => {
            if (stompClient && !isConnect) {
                stompClient.disconnect();
            }
        };
    }, []);

    useEffect(() => {
        if (isConnect && stompClient) {
            stompClient.subscribe(`/comment/${currentTicket}`, (message) => {
                const newComment = JSON.parse(message.body);
                setComments(prev => [...prev, newComment]);
            });
        }
    }, [stompClient, isConnect]);

    useEffect(() => {
        // Adiciona o listener de colagem
        window.addEventListener('paste', handlePaste);

        const handleEsc = (event) => {
            if (event.key === "Escape") {
                buttonRef.current.blur(); // Remove o foco do botão quando 'Esc' for pressionado
            }
        };

        document.addEventListener("keydown", handleEsc);

        // Cleanup: remove o event listener quando o componente for desmontado
        return () => {
            document.removeEventListener("keydown", handleEsc);
            window.removeEventListener('paste', handlePaste);
        };

    }, []);


    const handlePaste = (event) => {
        const items = event.clipboardData.items;
        let newFiles = []; // Armazena os novos arquivos

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === 'file') {
                setShowNewAttachmentDialog(true);
                const file = item.getAsFile();
                const objectURL = URL.createObjectURL(file);
                file.objectURL = objectURL;
                newFiles.push(file);
            }
        }

        if (newFiles.length > 0) {
            setTimeout(() => {
                setSelectedFiles(prev => [...prev, ...newFiles]);


                if (fileUploadRef.current) {
                    fileUploadRef.current.setFiles(prev => [...prev, ...newFiles]);

                    if (fileUploadRef.current.props.onFileSelect) {
                        const fakeEvent = {
                            originalEvent: event,
                            files: newFiles,
                        };
                        fileUploadRef.current.props.onFileSelect(fakeEvent);
                    }
                }
            }, 100);
        }
    };

    const listCalledByTicket = async (ticket) => {
        try {
            const response = await calledService.getCalledByTicket(ticket);
            setCalled(response.data);
            setComments(response.data.comments.sort((a, b) => a.id - b.id));
            setTimeout(() => {
                setLoading(false)
            }, 300);
        } catch (error) {
            toast.error(error.response.data.message);
            setLoading(false)
        }
    };

    const fileDownload = async (filename) => {
        try {
            const response = await calledService.downloadFile(filename);
            FileDownload(response.data, filename.split("$$")[0]);
        } catch (error) {
            toast.error(error.message);
        }
    };

    const handleSendAttachment = async () => {
        setLoadingAttchment(true);
        const formData = new FormData();
        for (let file of selectedFiles) {
            formData.append('files', file);
        }

        try {
            await calledService.addAttchamentComment(called.id, user.sub, formData);
            setShowAttachment(false);
            setShowNewAttachmentDialog(false);
        } catch {
            toast.error("Não foi possível anexar arquivo a este chamado. Tente novamente ou contate o administrador!");
        } finally {
            setLoadingAttchment(false);
        }
    };

    const resizeImage = (file, maxWidth, maxHeight, callback) => {
        const img = new window.Image(); // Use a classe Image nativa do JavaScript
        const reader = new FileReader();

        reader.onload = function (e) {
            img.src = e.target.result;

            img.onload = function () {
                const canvas = canvasRef.current;

                if (!canvas) {
                    console.error('Canvas não encontrado.');
                    return;
                }

                const ctx = canvas.getContext('2d');
                if (!ctx) {
                    console.error('Contexto 2D não disponível.');
                    return;
                }

                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);
                canvas.toBlob(callback, file.type);
            };
        };

        reader.readAsDataURL(file);
    };

    const handleFileSelect = (event) => {
        const files = Array.from(event.files);
        const newFiles = [];
        const resizePromises = files.map(data => {
            return new Promise((resolve, reject) => {
                if (data.type.startsWith("image/")) {
                    resizeImage(data, 1024, 768, (blob) => {
                        const newFile = new File([blob], data.name, {
                            type: data.type,
                        });
                        newFiles.push(newFile);
                        resolve();
                    });
                } else {
                    newFiles.push(data);
                    resolve();
                }
            });
        });

        Promise.all(resizePromises).then(() => {
            setSelectedFiles(newFiles);
        }).catch(error => {
            console.error("Error resizing images: ", error);
        });
    };

    const handleFileRemove = (event) => {
        const files = selectedFiles;
        const index = files.indexOf(event.file);
        files.splice(index, 1);
        setSelectedFiles(selectedFiles);
    };

    const cancelDialogNewAttachment = () => {
        setShowNewAttachmentDialog(false);
        setSelectedFiles([]);
    };

    const forceUpdate = () => {
        listCalledByTicket(currentTicket);
    };

    const attComment = async (e) => {
        e.preventDefault();
        if (comment.comment.trim() !== '') {
            try {
                await calledService.addComment(called.ticket, comment);
                setComment(initialComment);
            } catch {
                toast.error("Erro ao proseguir com solicitação. Seu comentário não foi salvo!");
            }
        }

        const textarea = document.querySelector('textarea[name="comment"]');
        textarea.style.height = "34px";
    };

    const deleteComment = () => {
        const data = selectedComment;
        commentService.del(data.id, data.userEmail).then(() => {
            forceUpdate()
        }).catch((error) => {
            toast.error(error.response.data.message);
        })
    }

    const imagesTemplate = (image) => (
        <Image className='m-1 border' preview src={image} width='150' height='103' />
    );

    const returnStatus = (status) => (
        <Tag value={status} severity={getStatus(status)} />
    );

    const returnPriority = (priority) => (
        <Tag value={priority} severity={getSeverity(priority)} />
    );

    const updateStatus = (status) => {
        var solutionApplied = "";

        if (status === 3) {
            solutionApplied = "Resolvido"
        }

        calledService.updateCalledStatus(called.id, {
            solutionApplied: solutionApplied,
            newStatus: status
        }).then(() => {
            forceUpdate();
        })
    }

    const accept = () => {
        updateStatus(3);
    };

    const reject = () => {
        updateStatus(1);
    };

    const getStatus = (status) => {
        switch (status) {
            case 'ESCALADO':
                return 'danger';

            case 'RESOLVIDO':
                return 'success';

            case 'EXECUCAO':
                return 'warning';

            case 'ABERTO':
                return 'secondary';
        }
    };

    const getSeverity = (priority) => {
        switch (priority) {
            case 'BAIXA':
                return 'info';
            case 'MEDIA':
                return 'warning';
            case 'ALTA':
            case 'URGENTE':
                return 'danger';
            default:
                return null;
        }
    };

    const returnDate = (data) => (
        data ? moment(data).format("DD/MM/yyyy HH:mm:ss") : ""
    );

    const returnAttribute = (data) => {
        switch (data) {
            case 'true':
                return 'Sim';
            case 'false':
                return "Não";
            default:
                return data;
        }
    }

    const returnChatDate = (data) => (
        data ? moment(data).format("DD/MM HH:mm") : ""
    );

    const dataCalledStyle = window.innerWidth > 600 ? { height: '100%' } : {};

    const adjustTextareaHeight = (e) => {
        const textarea = e.target;
        textarea.style.height = '35px'; // Resetar a altura para recalcular
        const maxHeight = parseInt(window.getComputedStyle(textarea).lineHeight) * 4; // Calcula a altura máxima para 3 linhas
        textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`; // Ajusta a altura, mas não ultrapassa 3 linhas
    };

    const keyDown = (e) => {
        if (e.key === "Enter") {
            if (e.ctrlKey || e.altKey || e.shiftKey) {
                // Quebra a linha
                e.preventDefault();
                // Adicione a lógica para quebrar a linha aqui, por exemplo:
                const textarea = e.target;
                const start = textarea.selectionStart;
                const end = textarea.selectionEnd;
                textarea.value = textarea.value.substring(0, start) + "\n" + textarea.value.substring(end);
                textarea.selectionStart = textarea.selectionEnd = start + 1;
            } else {
                // Envia o formulário
                e.preventDefault();
                attComment(e);
            }
        }
    }

    return (
        <div className='data-called' style={dataCalledStyle}>
            <OverlayPanel ref={op}>
                <div className='p-items' onClick={() => op.current.hide()}>
                    <button type='button' disabled={called.status === 'RESOLVIDO'} onClick={() => setShowNewAttachmentDialog(true)}>
                        <i className='bi bi-images' /> Fotos e arquivos
                    </button>
                    <button onClick={forceUpdate}><i className='bi bi-arrow-repeat' /> Sincronizar</button>
                    {called.status === "RESOLVIDO" || called.status === "FECHADO" ?
                        <button onClick={reject}><i className='bi bi-x-square-fill' /> Não resolvido</button>
                        :
                        <button onClick={accept}><i className='bi bi-check-square-fill' /> Resolvido</button>
                    }
                </div>
            </OverlayPanel>
            <div className={`button-select mat-elevation-z2 ${window.innerWidth > 700 ? 'd-none' : ''}`}>
                <a onClick={() => setCommentsVisible(!commentsVisible)}>{commentsVisible ? 'Exibir Comentários' : 'Exibir Detalhes'}</a>
            </div>
            <div className='view-called'>
                <div className={`col-md-5 ${commentsVisible && window.innerWidth < 700 ? 'd-none' : ''}`}>
                    <div className='chat' ref={chatRef} >
                        {loading ?
                            <div className="chat-loading">
                                <div className="">
                                    <ProgressSpinner />
                                </div>
                            </div>
                            :

                            <div className="chat-comment">
                                {comments.map((commentObj, index) => (
                                    <>
                                        {commentObj.userName ?
                                            <div key={index} className={user.sub === commentObj.userEmail ? "message-main-sender" : "message-main-receiver"}>
                                                <Card className={user.sub === commentObj.userEmail ? "send mat-elevation-z3 mb-3" : "recipient mat-elevation-z3 mb-3"}>
                                                    <div key={index} className="comments">
                                                        {user.sub !== commentObj.userEmail &&
                                                            <span className="comment-recipient">
                                                                {commentObj.userName && <strong>{commentObj.userName.split(' ').slice(0, 1)}</strong>}
                                                            </span>
                                                        }
                                                        <div className='comment'>
                                                            {commentObj.file === true ?
                                                                <GetImage comments={comments} comment={commentObj} calledService={calledService} />
                                                                :
                                                                <span className="comment-text">{commentObj.comment}</span>
                                                            }
                                                            <span className="message-time pull-right">{returnChatDate(commentObj.date)}</span>
                                                        </div>
                                                        {user.sub === commentObj.userEmail &&
                                                            <button className='comments-items' onClick={(e) => { opx.current.toggle(e); setSelectedComment(commentObj) }} >
                                                                <i className="pi pi-angle-down pointer"></i>
                                                            </button>
                                                        }
                                                        <OverlayPanel ref={opx}>
                                                            <div className='overlay-items' onClick={() => opx.current.hide()}>
                                                                <div onClick={() => deleteComment()}>
                                                                    <i className="bi bi-trash3-fill" />
                                                                </div>
                                                            </div>
                                                        </OverlayPanel>
                                                    </div>
                                                </Card>
                                            </div>
                                            :
                                            <div className='d-flex justify-content-center mb-3'>
                                                {commentObj.comment}
                                            </div>
                                        }
                                    </>
                                ))}
                            </div>
                        }
                    </div>
                    <form id='submitComment' className='p-form-group w-100' onSubmit={attComment} >
                        <div className="p-inputgroup">
                            <button ref={buttonRef} type='button' className="p-inputgroup-addon btn-plus" onClick={(e) => op.current.toggle(e)}>
                                <i className='bi bi-plus' />
                            </button>
                            <textarea
                                className='textarea-group input-group'
                                name="comment"
                                rows={1}
                                onKeyDown={keyDown}
                                onInput={adjustTextareaHeight}
                                disabled={called.status === "RESOLVIDO"}
                                placeholder={called.status !== 'RESOLVIDO' ? "Digite uma mensagem" : "Digite uma mensagem"}
                                value={comment.comment}
                                onChange={(e) => setComment({
                                    ...comment,
                                    comment: e.target.value,
                                    date: DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"),
                                    userEmail: LocalStorageService.getItem("@YORK:User").email,
                                    readSender: false,
                                    readReceiver: true
                                })}
                            />
                            <button
                                className="p-inputgroup-addon btn-send"
                                disabled={(comment.comment.trim() === '') || (called.status === 'RESOLVIDO')}>
                                <i className='bi bi-send-fill' />
                            </button>
                        </div>
                    </form>
                </div>
                {loading ?
                    <VisualizarChamadoSkeleton />
                    :
                    <div className={`col-md-7 card-details ${!commentsVisible && window.innerWidth < 700 ? 'd-none' : ''}`} >
                        <Accordion title={"Informação do chamado #" + called.ticket} onShow={e => setShowDetails(!showDetails)} show={showDetails}>
                            <Info title="Assunto" content={called.title} />
                            <Info title="Criador" content={called.requesterName} />
                            <Info title="Licença" content={called.branchName + " (" + called.branchCode + ")"} />
                            <Info title="Tipo de serviço" content={called.serviceTypeDescription} />
                            {Object.entries(called.attributes)
                                .sort((a, b) => {
                                    const titleA = a[0].split("___")[1] || "";
                                    const titleB = b[0].split("___")[1] || "";
                                    return titleA.localeCompare(titleB);
                                })
                                .map((obj) => (
                                    <Info
                                        key={obj[0]}
                                        title={obj[0].split("___")[1] || obj[0]}
                                        content={returnAttribute(obj[1])}
                                        type={obj[0].split("___")[0]}
                                    />
                                ))}
                            <Info title="Prioridade" content={returnPriority(called.priority)} />
                            <Info title="Status" content={returnStatus(called.status)} />
                            <Info title="Detalhes"
                                content={called.description.split('\n').map((line) => (
                                    <>{line}<br /></>
                                ))}
                            />
                            <Info title="Solução Aplicada"
                                content={
                                    called.solutionApplied !== null &&
                                    called.solutionApplied.split('\n').map((line) => (
                                        <>{line}<br /></>
                                    ))
                                }
                            />
                        </Accordion>
                        <Accordion title="Mídias" onShow={e => setShowAttachment(!showAttachment)} show={showAttachment}>
                            {images.length > 0 &&
                                <div className='attchaments'>
                                    <Carousel value={images} numVisible={3} numScroll={1} itemTemplate={imagesTemplate} responsiveOptions={[
                                        {
                                            breakpoint: '1400px',
                                            numVisible: 3,
                                            numScroll: 1
                                        },
                                        {
                                            breakpoint: '1199px',
                                            numVisible: 3,
                                            numScroll: 1
                                        },
                                        {
                                            breakpoint: '767px',
                                            numVisible: 2,
                                            numScroll: 1
                                        },
                                        {
                                            breakpoint: '575px',
                                            numVisible: 1,
                                            numScroll: 1
                                        }
                                    ]} />
                                </div>
                            }
                            <div className='container'>
                                {(files.length > 0) || (images.length > 0) ?
                                    <>
                                        {files.map((attachment) => (
                                            <button className='file-download' onClick={() => fileDownload(attachment)}>
                                                {attachment.split('$$')[0]}
                                            </button>
                                        ))}

                                    </>
                                    :
                                    <span className='null-comment'>Nenhum anexo foi adicionado neste chamado</span>
                                }
                            </div>
                        </Accordion>
                        <Accordion title="Responsável" onShow={e => setShowResponsibility(!showResponsibility)} show={showResponsibility}>
                            {called.assigneeEmail ?
                                <div className='d-flex'>
                                    <div className='profile-requester'>
                                        <i className='bi bi-person-circle' />
                                    </div>
                                    <div className='d-flex row mx-2'>
                                        <strong>{called.assigneeName}</strong>
                                        <label>{called.assigneeEmail}</label>
                                    </div>
                                </div>
                                :
                                <span className='null-comment'>Ninguém assumiu essa tarefa</span>
                            }
                        </Accordion>
                        <Accordion title="Datas" onShow={e => setShowDates(!showDates)} show={showDates}>
                            <Info title="Criado" content={returnDate(called.creationDate)} />
                            <Info title="Em execução" content={returnDate(called.taskStartTime)} />
                            <Info title="Resolvido" content={returnDate(called.updateDate)} />
                        </Accordion>
                        <Accordion title="Solicitante" onShow={e => setShowRequester(!showRequester)} show={showRequester}>
                            <div className='d-flex'>
                                <div className='profile-requester'>
                                    <i className='bi bi-person-circle' />
                                </div>
                                <div className='d-flex row mx-2'>
                                    <strong>{called.requesterName}</strong>
                                    <label>{called.requesterEmail}</label>
                                </div>
                            </div>
                        </Accordion>
                    </div>
                }
            </div>
            <Dialog
                header="Envio de anexo"
                visible={showNewAttachmentDialog}
                className='col-10 col-md-6'
                footer={
                    <div className='d-flex justify-content-end'>
                        <Button
                            label="Cancelar"
                            icon="pi pi-times"
                            disabled={loadingAttchment}
                            onClick={cancelDialogNewAttachment}
                            className="btn btn-danger mx-2"
                        />
                        <Button
                            label="Enviar"
                            icon="pi pi-check"
                            disabled={loadingAttchment || selectedFiles.length < 1 || called.status == "FECHADO" || called.status == "RESOLVIDO"}
                            loading={loadingAttchment}
                            className="btn btn-primary"
                            onClick={handleSendAttachment} />
                    </div>
                }
                modal={true}
                onHide={cancelDialogNewAttachment}>
                <div className='p-4'>
                    <canvas ref={canvasRef} style={{ display: 'none' }} />
                    <FileUpload
                        ref={fileUploadRef}
                        onRemove={handleFileRemove}
                        name="files"
                        on
                        multiple
                        uploadHandler={handleFileSelect}
                        auto
                        chooseLabel="Selecionar"
                        customUpload
                        emptyTemplate={<p className="m-0">Arraste e solte os arquivos aqui para fazer upload.</p>} />
                    <span className='null-comment'>Por favor, anexe arquivos com tamanho inferior a 20 MB.</span>
                </div>
            </Dialog>
        </div>
    );
};

const Accordion = (props) => {
    return (
        <div className='accordion'>
            <div className="accordion-ticket" onClick={props.onShow}>
                <h5>{props.title}</h5>
                <i className={'pi ' + (props.show ? 'pi-angle-down' : 'pi-angle-right')} />
            </div>
            {props.show &&
                <>
                    {props.children}
                </>
            }
        </div>
    )
}

const Info = (props) => {
    return (
        <div key={props.title} className="data-info">
            <span className="data-info-title">{props.title}: </span>
            <span className="data-info-content">{props.content}</span>
        </div>
    )
}

const GetImage = ({ comment, calledService }) => {
    const [commentImage, setCommentImage] = useState(null);
    const [loading, setLoading] = useState(true);
    const FileDownload = require('js-file-download');

    useEffect(() => {
        if (comment.file) {
            const fetchImage = async () => {
                setLoading(true)
                try {
                    const response = await calledService.getAttachments(comment.filename);
                    const blob = response.data;
                    const url = window.URL.createObjectURL(blob);
                    setCommentImage(url);
                    setLoading(false);
                } catch (error) {
                    toast.error(error.message);
                    setLoading(false);
                }
            };

            fetchImage();
        }
    }, []);

    const fileDownload = (filename) => {
        calledService.downloadFile(filename).then(response => {
            FileDownload(response.data, filename.split("$$")[0])
        }).catch(error => {
            toast.error(error.message);
        })
    };

    const splitFilename = (filename) => {
        const splitName = filename.split("$$")[0];
        const nameInicial = splitName.slice(0, 30);
        const nameFinal = splitName.slice(-7);
        return splitName.length > 30 ? nameInicial + '...' + nameFinal : nameInicial + nameFinal;
    }

    return (
        <div>
            <div>
                {loading ?
                    <div className='d-flex justify-content-center align-items-center'>
                        <ProgressSpinner style={{ width: '25px', height: '25px' }} className='mx-2' />
                        <span className='comment-file'>{splitFilename(comment.filename)}</span>
                    </div>
                    :
                    <>
                        {commentImage && (
                            isImage(comment.filename) ?
                                <div className='d-grid comment-image'>
                                    <Image
                                        src={commentImage}
                                        alt={`Comment attachment`}
                                        width='240'
                                        preview />
                                </div>
                                :
                                <div className='comment-file' onClick={() => fileDownload(comment.filename)}>
                                    <i style={{ fontSize: '25px' }} className='bi bi-arrow-down-circle-fill  mx-2' />
                                    <span  >{splitFilename(comment.filename)}</span>
                                </div>
                        )}
                    </>
                }
            </div>
        </div>
    );
};
