import LOGO from '../../assets/York-logo-fundobranco.jpg';
import "./styles.css"

function LoadingPage() {
    return (
        <div className="loading-page">
            <img className="loadi" src={LOGO} />
        </div>
    )
}

export default LoadingPage;